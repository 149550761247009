import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Section } from "./sections"

import { ColumnSection, ColumnLayout } from "../elements/grids.js"
import { HeroTitle, HeroBody } from "../typography"
import { CtaPrimary, CtaSecondary } from "../elements/buttons.js"
import NavigateToOtherPageButtons, {
  Footer,
} from "../molecules/ButtonFooter.js"
const HeroWrap = styled(Section)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  overflow: hidden;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "inherit"};
  color: "inherit";
  padding-top: 50px;
  padding-bottom: 116px;

  @media (min-width: 1080px) {
    max-width: ${props => (props.wide ? "100%" : "1080px")};
    padding-right: ${props => (props.wide ? "calc((100vw - 1080px)/2)" : "0")};
    padding-left: ${props => (props.wide ? "calc((100vw - 1080px)/2)" : "0")};
    width: ${props => (props.wide ? "auto" : "100%")};
  }
`
const HeroMainDescription = styled(HeroBody)`
  text-align: center;
  width: 60%;
  font-size: 24px
  opacity: 0.8;
  color: inherit;
  margin: 0 auto;
  padding: 25px 16px 16px 16px;
  @media (max-width: 300px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
    text-align: center;
  }
  @media (max-width: 800px) {
    font-size: 3vw;
  }
`
const Img = styled.img`
  width: ${props => props.width};
  position: absolute;
  top: ${props => (props.top ? props.top : "160px")};
  background-color: transparent;
`

const ImageContainer = styled.div`
  width: ${props => props.width};
  height: auto;
  @media (max-width: 880px) {
    position: absolute;
    opacity: 0.2;
    top: 75px;
    left: calc(50vw - ${props => props.width} / 2 - 25px);
  }
  @media (max-width: 800px) {
    top: 65px;
  }

  @media (max-width: 450px) {
    transform: scale(0.7);
    top: 75px;
  }
  @media (max-width: 300px) {
    transform: scale(0.6);
  }
`
const Hero = ({
  title,
  body,
  theme,
  img,
  imgWidth,
  ctaPrimary,
  ctaSecondary,
  backgroundColor,
}) => {
  return (
    <HeroWrap reducePaddingTop backgroundColor={backgroundColor}>
      <ColumnLayout dataColumns={"2,2"}>
        <ColumnSection>
          <HeroTitle children={title} />
          <HeroMainDescription children={body} />
          <Footer>
            <CtaPrimary
              backgroundColor={"black"}
              height={"20px"}
              label={ctaPrimary.label}
            />

            <CtaSecondary
              color={"black"}
              height={"20px"}
              label={ctaSecondary.label}
            />
          </Footer>
        </ColumnSection>
        <ImageContainer width={imgWidth}>
          <Img src={img} width={imgWidth} />
        </ImageContainer>
      </ColumnLayout>
    </HeroWrap>
  )
}

export default Hero

const ImgCustom = styled.img`
  width: ${props => props.width};
  position: relative;
  top: ${props => (props.top ? props.top : "160px")};
  left: ${props => (props.left ? props.left : "0")};
  background-color: transparent;
  z-index: -2;
`
export const McatHero = ({
  title,
  body,
  theme,
  img,
  imgWidth,
  imgStyle,
  ctaPrimary,
  ctaSecondary,
  backgroundColor,
}) => {
  return (
    <HeroWrap backgroundColor={backgroundColor}>
      <ColumnLayout dataColumns={"2,2"}>
        <ColumnSection>
          <HeroTitle children={title} float="left" />
          <HeroBody
            style={{ width: "75%" }}
            children={
              Array.isArray(body)
                ? body.map((segment, index) => {
                    return (
                      <React.Fragment key={"heroBodyPart" + index}>
                        {segment}
                      </React.Fragment>
                    )
                  })
                : body
            }
          />
          <Footer width={"70%"}>
            <CtaPrimary
              backgroundColor={"black"}
              height={"20px"}
              label={ctaPrimary.label}
              link={ctaPrimary.redirect}
              style={{
                width: "120.8px",
                margin: "10px 0",
                justifyContent: "center",
              }}
            />
            <CtaPrimary
              backgroundColor={"#F50057"}
              height={"20px"}
              label={ctaSecondary.label}
              link={ctaSecondary.redirect}
            />
          </Footer>
        </ColumnSection>
        {img ? (
          <ImageContainer width={imgWidth}>
            <ImgCustom
              src={img}
              width={imgWidth}
              style={imgStyle ? imgStyle : null}
              top="0"
              left="30px"
            />
          </ImageContainer>
        ) : null}
      </ColumnLayout>
    </HeroWrap>
  )
}

export const HeroMainPage = ({ title, children, ctaPrimary, ctaSecondary }) => {
  return (
    <Section style={{ paddingTop: "116px", paddingBottom: "0px" }}>
      <HeroTitle fontSize="8.5vw" float="center">
        {title}
      </HeroTitle>
      <HeroMainDescription>{children}</HeroMainDescription>
      <br />
      <br />
      <NavigateToOtherPageButtons
        float={"center"}
        width={"65%"}
        style={{ flexFlow: "row wrap" }}
        ctaPrimary={ctaPrimary}
        ctaSecondary={ctaSecondary}
      />
    </Section>
  )
}
