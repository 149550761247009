// import React from 'react';
import styled from "styled-components"

const columnRowGap = `32px`
// const columnPaddingNormal = `16px`;

export const ColumnLayout = styled.div`
  display: grid;
  row-gap: ${columnRowGap};
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  @media (max-width: 880px) {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto;
  }
`

export const ColumnSection = styled.section`
  display: grid;
  row-gap: ${columnRowGap};
`

export const Aside3xGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 80px;
  width: 100%;
  @media (max-width: 800px) {
    display: flex;
    flex-flow: column nowrap;
    width: 80%;
    margin: 20px auto;
  }
`

export const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.textLocation === "right" ? "2.55fr 2fr" : "2fr 2.55fr"};
  grid-template-rows: ${props =>
    props.textLocation === "right" ? "0.2fr 0.8fr" : "0.2fr 0.8fr"};
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  @media (max-width: 800px) {
    display: flex;
    flex-flow: column nowrap;
    width: 80%;
    margin: 30px auto;
  }
`

export const QuoteGrid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.textLocation === "right" ? "2.55fr 2fr" : "2fr 2.55fr"};
  grid-column-gap: 32px;
`
