import React from "react"
import Layout from "../../components/layout.js"
import SEO from "../../components/seo.js"
// import { navigate } from "gatsby"
import { InPageNav } from "../../components/navbar.js"
import { McatHero } from "../../components/sections/heroSection.js"

//SVGs
import BusinessSVG from "../../images/setup_analytics.svg"

// const card1 = {
//   packageName: "",
//   price: "",
//   duration: "",
//   description: "",
//   onClick: "",
//   offered: [
//     "4+ Hour Days",
//     "505+ Score Guarantee",
//     "Be Fully Prepared In 4-6 Months",
//     "90+ Hours Recorded Video Content",
//     "Master-Focussed Approach",
//     "7000+ Content Questions",
//   ],
//   notOffered: [],
// }

// const card2 = {
//   packageName: "",
//   price: "",
//   duration: "",
//   description: "",
//   onClick: "",
//   offered: [
//     "8+ Hour Days",
//     "510+ Score Guarantee",
//     "Be Fully Prepared In 3-4 Months",
//     "90+ Hours Recorded Video Content",
//     "Master-Focussed Approach",
//     "7000+ Content Questions",
//   ],
//   notOffered: [],
// }
// const card3 = {
//   packageName: "",
//   price: "",
//   duration: "",
//   description: "",
//   onClick: "",
//   offered: [
//     "12+ Hour Days",
//     "515+ Score Guarantee",
//     "Be Fully Prepared In 1-2 Months",
//     "90+ Hours Recorded Video Content",
//     "Master-Focussed Approach",
//     "7000+ Content Questions",
//   ],
//   notOffered: [],
// }

const GMAT = ({ location }) => {
  const RedirectToPage = dest => {
    // navigate(dest)
  }

  return (
    <Layout>
      <SEO title="GMAT Test Prep." />
      <InPageNav
        pageTitle="GMAT"
        currentPath={location.pathname}
        navItems={[
          { link: "/gmat", label: "Overview" },
          { link: "/gmat/how_it_works", label: "How It Works" },
          { link: "/gmat/schedule", label: "Class Schedule" },
        ]}
        clickCallback={RedirectToPage}
      />
      <McatHero
        title="GMAT"
        body="The student-centric approach to the GMAT"
        ctaPrimary={{ label: "Buy Course", redirect: "/gmat/schedule/" }}
        ctaSecondary={{ label: "Request Demo", redirect: "/contact" }}
        img={BusinessSVG}
        imgWidth="450px"
        imgStyle={{ top: "0px" }}
        backgroundColor="inherit"
      />
      <h3 style={{ textAlign: "center", width: "80%", margin: "0 auto" }}>
        Oh{" "}
        <span style={{ fontSize: "22px" }} role="img" aria-label="Poop face">
          >💩
        </span>
        . We're so sorry! We're updating this section of our website. Please
        reach out via chat or email us to know more information{" "}
        <span role="img" aria-label="Happy face">
          😄
        </span>
      </h3>
    </Layout>
  )
}

export default GMAT
