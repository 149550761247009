import React from "react"
import styled from "styled-components"
import { CtaPrimary, CtaSecondary } from "../elements/buttons.js"

export const Footer = styled.footer`
  padding: ${props => (props.noPadding ? "0px" : "0 16px 16px")};
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.align ? props.align : "space-between")};
  align-items: center;
  width: ${props => (props.width ? props.width : "55%")};
  margin: ${props => (props.float ? "0 auto" : "0")};
  @media (max-width: 880px) {
    width: calc(100% - 32px);
  }
  @media (max-width: 450px) {
    padding: 0px;
    width: 200px;
    justify-content: center;
    margin: 0 auto;
  }
`
const cardColors = [
  "#BD43DE",
  "#BD43DE",
  "#BD43DE",
  "#BD43DE",
  "#5C44CC",
  "#4072F8",
  "#4072F8",
  "#5C44CC",
]
const NavigateToOtherPageButtons = ({
  ctaPrimary,
  ctaSecondary,
  float,
  width,
  style,
  children,
  noPadding,
  align,
}) => {
  return (
    <Footer
      float={float}
      width={width}
      style={style}
      noPadding={noPadding}
      align={align}
    >
      {ctaPrimary &&
        ctaPrimary.length > 0 &&
        ctaPrimary.map((cta, index) =>
          cta.label && cta.link ? (
            <CtaPrimary
              key={cta.label + index}
              backgroundColor={cta.color || cardColors[index]}
              height={"20px"}
              label={cta.label}
              link={cta.link}
              style={
                cta.style || {
                  margin: "10px auto",
                  minWidth: "114.8px",
                  justifyContent: "center",
                }
              }
            />
          ) : null
        )}
      {children}
    </Footer>
  )
}

export default NavigateToOtherPageButtons
